const formatNumber = (n) => {
  n = n.toString();
  return n[1] ? n : `0${n}`;
};

export function formatDate(number, format = "Y/M/D") {
  var formateArr = ["Y", "M", "D", "h", "m", "s"];
  var arr = [];
  var date = new Date(number);
  arr.push(date.getFullYear());
  arr.push(formatNumber(date.getMonth() + 1));
  arr.push(formatNumber(date.getDate()));
  arr.push(formatNumber(date.getHours()));
  arr.push(formatNumber(date.getMinutes()));
  arr.push(formatNumber(date.getSeconds()));
  for (var i in arr) {
    format = format.replace(formateArr[i], arr[i]);
  }
  return format;
}
