<template>
  <div>
    <el-card v-loading="loading" shadow="hover">
      <div slot="header">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              v-model="user_name"
              placeholder="请输入学生姓名"
              @input="onUserName"
            ></el-input>
          </el-col>
          <el-col :span="5">
            <el-input
              v-model="by_user_name"
              placeholder="请输入员工姓名"
              @input="onByUserName"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-date-picker
              v-model="scan_date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="onChange">
            </el-date-picker>
          </el-col>
          <el-col :span="8" class="vv-text_r"></el-col>
        </el-row>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="user" label="学生" width="100">
        </el-table-column>
        <el-table-column prop="by_user" label="员工" width="100">
        </el-table-column>
        <el-table-column prop="scan_time" label="扫码时间">
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="onCurrentChange"
        hide-on-single-page
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { formatDate } from "@/utils/common"
import { getRecord } from "@/api/record";
import {
  Card,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Table,
  TableColumn,
  Pagination,
  Tag,
} from "element-ui";
export default {
  name: "Record",
  components: {
    [Card.name]: Card,
    [Row.name]: Row,
    [Col.name]: Col,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Tag.name]: Tag,
  },
  data() {
    return {
      loading: false,
      user_name: null,
      by_user_name: null,
      scan_date: [],
      tableData: [],
      pagesize: 10,
      total: 0,
      currentPage: 1,
    };
  },
  methods: {
    onUserName() {
      this.fetchData();
    },
    onByUserName() {
      this.fetchData();
    },
    onChange(e) {
      if (e === null) {
        this.scan_date = []
      }
      this.fetchData();
    },
    onCurrentChange(val) {
      this.currentPage = val;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      getRecord({
        page_id: this.currentPage,
        page_size: this.pagesize,
        user_name: this.user_name,
        by_user_name: this.by_user_name,
        scan_date_from: this.scan_date[0],
        scan_date_to: this.scan_date[1]
      })
        .then((rs) => {
          const data = rs.data.data
          if (data) {
            this.tableData = data.map(item => {
              return {
                by_user: item['by_user'],
                user: item['user'],
                scan_time: formatDate(item['scan_time'], 'Y-M-D h:m:s')
              }
            });
            this.total = rs.data.count;
          } else {
            this.tableData = []
            this.total = 0
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
